body{
  background-color: #fff;
}
.App {
  text-align: center;
  min-height: 100vh;
}

.input-antd{
  padding-top: 4px;
  padding-bottom: 4px;
  line-height: 1.5715;
  outline: none;
  appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
  border-radius: var(--border-radius-small);
  color: var(--color-text-1);
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  box-sizing: border-box;
  transition: color .1s cubic-bezier(0, 0, 1, 1), border-color .1s cubic-bezier(0, 0, 1, 1), background-color .1s cubic-bezier(0, 0, 1, 1);
  background-color: transparent;
  margin-left: 10px;
  border: 1px solid var(--color-neutral-3);
}
.input-antd:hover {
  background-color: transparent;
  border-color: rgb(var(--primary-5));
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


.btn-header {
  line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border-color: #d9d9d9;
}

.btn-header-primary {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.content {
  padding: 50px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.custom-table-class .rc-virtual-list-holder{
 height: 400px;
}
/* ====================================================== */




.table-enter-leave-demo-action-bar {
  margin: 20px 0;
  background: #fff;
  /* line-height: 40px;
  height: 40px; */
  position: relative;
  border-radius: 4px;
}





.table-enter-leave-demo-action-bar button {
  /* position: absolute;
  right: 10px;
  top: 0;
  bottom: 0; */
  /* margin: auto; */
  /* width: 80px; */
  /* height: 20px; */
  border-radius: 4px;
  line-height: 1;
}
.loader-wrapper{
  position: fixed;
    inset: 0;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ant-spin-container.ant-spin-blur{
  opacity: 0;
}

.header-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #2B3A4A!important;
}
.color-light{
  color: #fff;
}
.header-wrapper .module-name-wrapper a{
  display: flex;
  align-items: center;
}
.icon-size-custom{
    font-size: 18px;
}
.header-wrapper .module-name-wrapper a:hover{
  color: #69b1ff;
}
.header-wrapper .module-name-wrapper a:hover h2{
  color: #69b1ff;
}
.header-wrapper .module-name-wrapper a .icon-wrapper-custom{
  padding-right: 5px;
}
.header-wrapper .module-name-wrapper a .header-icon-custom:hover{
  color: #69b1ff;
}
.header-icon-custom:hover{
  color: #69b1ff;
}
.toastify-custom-class .ant-message-notice-content{
  z-index: 99999999;
  position: relative;
}
.header-wrapper .logo-wrapper{
  height: 85%;
  width: auto;
}

.header-wrapper .module-name-wrapper h2{
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  margin: 0;
}
.header-wrapper .nav-actions{
  display: flex;
  align-items: center;
  height: 100%;
}
.header-wrapper .nav-actions .op-name{
  color: #fff;
  font-size: 18px;
  margin-left: 8px;
  margin-right: 20px;
  display: flex;
align-items: center;
text-transform: capitalize;
}

.alert-left-align {
  text-align: left;
}

/* ====================================================== */

.container{
  position:relative;
  margin-left:auto;
  margin-right:auto;
  padding-right:15px;
  padding-left:15px
}
@media (min-width: 476px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 768px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 992px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 1200px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 476px){
  .container{
    width:100%
  }
}
@media (min-width: 768px){
  .container{
    width:720px;
    max-width:100%
  }
}
@media (min-width: 992px){
  .container{
    width:960px;
    max-width:100%
  }
}
@media (min-width: 1200px){
  .container{
    width:1140px;
    max-width:100%
  }
}
@media (min-width: 1400px){
  .container{
    width:1340px;
    max-width:100%
  }
}